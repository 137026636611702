export const getQueryBySearchText = (searchText: string) => {
  let shouldBlock = {
    minimum_should_match: 1,
    should: [],
  }

  const converted = parseInt(searchText, 10)
  if (!Number.isNaN(converted)) {
    shouldBlock.should.push({
      match: {
        priority: converted,
      },
    })
  }

  const expression = {
    value: `*${searchText}*`,
    case_insensitive: true,
  }

  const underscoreExpression = {
    value: `*${searchText.replace(' ', '_')}*`,
    case_insensitive: true,
  }

  shouldBlock.should.push({
    wildcard: {
      wellId: expression,
    },
  })
  shouldBlock.should.push({
    wildcard: {
      sourceSystem: expression,
    },
  })
  shouldBlock.should.push({
    wildcard: {
      role: underscoreExpression,
    },
  })
  shouldBlock.should.push({
    wildcard: {
      taskType: expression,
    },
  })
  shouldBlock.should.push({
    wildcard: {
      description: expression,
    },
  })
  shouldBlock.should.push({
    wildcard: {
      lastNote: expression,
    },
  })

  return {
    bool: shouldBlock,
  }
}
